
class MenuLink {
  constructor(public name: string, public url: string, public id: string) {}
}
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
  props: {
    applicationName: String,
    links: {
        type: Array,
        default: function() {
          return [
            new MenuLink("Home", "/","menu-home"),
            new MenuLink("Download", "/download/programs.html", "menu-downloads"),
            new MenuLink("Status Lists", "/status/status.html","menu-status-lists"),
            new MenuLink("Examples", "/examples/examples.html","menu-examples"),
            new MenuLink("Training", "/training/training.html","menu-training"),
            new MenuLink("License Troubleshooting", "/support/LicenseTroubleshooting_StandaloneLicense.html", "menu-license-troubleshooting"),
        ];
      }
    }
  },
  data() {
    return {
      showMenu: false
    }
  }
});
